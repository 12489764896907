import type {
  OrganizationPermissions } from '~/src/enums/ProjectPermissions'
import {
  OWNER_PERMISSIONS,
  ADMIN_PERMISSIONS,
  MANAGER_PERMISSIONS,
  VIEWER_PERMISSIONS,
} from '~/src/enums/ProjectPermissions'
import { useUser } from '~/composables/data/useUser'
import { OrganizationRole } from '~/src/enums/OrganizationRole'
import { useCurrentProject } from '~/composables/data/useCurrentProject'
import { isUndefined } from 'lodash-es'
import { useCurrentMediaProject } from '~/composables/data/useCurrentMediaProject'

export function usePermissions() {
  const { user, getCurrentProjectRole, getCurrentOrganizationRole, getCurrentMediaProjectRole } = useUser()

  const { currentProject } = useCurrentProject(user.value?.currentCampaign?.defaultProject?.id)

  const { data: currentMediaProject } = useCurrentMediaProject(user.value?.currentMediaProject?.id)

  const currentRole = computed(() => {
    const orgRole = getCurrentOrganizationRole(user.value, user.value?.currentOrganization)
    const projectRole = getCurrentProjectRole(user.value, currentProject.value)
    const mediaProjectRole = getCurrentMediaProjectRole(user.value, currentMediaProject.value)

    if (isUndefined(orgRole)) {
      return
    }

    if (!isUndefined(projectRole)) {
      return projectRole > orgRole ? orgRole : projectRole
    }

    if (!isUndefined(mediaProjectRole)) {
      return mediaProjectRole > orgRole ? orgRole : mediaProjectRole
    }

    return orgRole
  })

  /**
   * Checks if the current user has a specific permission
   */
  const hasPermission = (permission: OrganizationPermissions): boolean => {
    const role = currentRole.value

    if (role === undefined) {
      return false
    }

    switch (role) {
      case OrganizationRole.OWNER:
        return OWNER_PERMISSIONS.has(permission)
          || ADMIN_PERMISSIONS.has(permission)
          || MANAGER_PERMISSIONS.has(permission)
          || VIEWER_PERMISSIONS.has(permission)
      case OrganizationRole.ADMIN:
        return ADMIN_PERMISSIONS.has(permission)
          || MANAGER_PERMISSIONS.has(permission)
          || VIEWER_PERMISSIONS.has(permission)
      case OrganizationRole.MANAGER:
        return MANAGER_PERMISSIONS.has(permission)
          || VIEWER_PERMISSIONS.has(permission)
      case OrganizationRole.VIEWER:
        return VIEWER_PERMISSIONS.has(permission)
      default:
        return false
    }
  }

  /**
   * Checks if the current user can perform a specific action and is in a valid state
   */
  const canPerform = (permission: OrganizationPermissions): boolean => {
    return !!user.value && hasPermission(permission)
  }

  return {
    currentRole,
    hasPermission,
    canPerform,
  }
}
