export enum OrganizationPermissions {
  CAN_DELETE_ORGANIZATION,
  CAN_INVITE_AND_REMOVE_MEMBERS,
  CAN_GRANT_ADMIN_RIGHTS,
  CAN_CREATE_PROJECTS,
  CAN_DOWNGRADE_ADMINS_AND_MANAGERS,
  CAN_VIEW_ALL_PROJECTS,
  CAN_VIEW_ORGANIZATION_SETTINGS,
  CAN_ACCESS_BILLING_AND_TRANSACTIONS,
  CAN_MAKE_TOP_UPS,
  CAN_BUY_PLACEMENTS,
  CAN_CANCEL_ORDERS,
  CAN_DOWNGRADE_MANAGERS,
  CAN_CREATE_CAMPAIGNS,
  CAN_APPROVE_PUBLISHED_ARTICLES,
  CAN_EDIT_ORDERS,
  CAN_VIEW_UNASSIGNED_ORDERS,
  CAN_VIEW_CAMPAIGNS,
}

export const OWNER_PERMISSIONS = new Set<OrganizationPermissions>([
  OrganizationPermissions.CAN_DELETE_ORGANIZATION,
  OrganizationPermissions.CAN_INVITE_AND_REMOVE_MEMBERS,
  OrganizationPermissions.CAN_GRANT_ADMIN_RIGHTS,
  OrganizationPermissions.CAN_CREATE_PROJECTS,
  OrganizationPermissions.CAN_DOWNGRADE_ADMINS_AND_MANAGERS,
  OrganizationPermissions.CAN_VIEW_ALL_PROJECTS,
  OrganizationPermissions.CAN_VIEW_ORGANIZATION_SETTINGS,
  OrganizationPermissions.CAN_ACCESS_BILLING_AND_TRANSACTIONS,
  OrganizationPermissions.CAN_MAKE_TOP_UPS,
  OrganizationPermissions.CAN_BUY_PLACEMENTS,
  OrganizationPermissions.CAN_CANCEL_ORDERS,
  OrganizationPermissions.CAN_DOWNGRADE_MANAGERS,
  OrganizationPermissions.CAN_CREATE_CAMPAIGNS,
  OrganizationPermissions.CAN_APPROVE_PUBLISHED_ARTICLES,
  OrganizationPermissions.CAN_EDIT_ORDERS,
  OrganizationPermissions.CAN_VIEW_UNASSIGNED_ORDERS,
  OrganizationPermissions.CAN_VIEW_CAMPAIGNS,
])

export const ADMIN_PERMISSIONS = new Set<OrganizationPermissions>([
  OrganizationPermissions.CAN_INVITE_AND_REMOVE_MEMBERS,
  OrganizationPermissions.CAN_GRANT_ADMIN_RIGHTS,
  OrganizationPermissions.CAN_CREATE_PROJECTS,
  OrganizationPermissions.CAN_DOWNGRADE_ADMINS_AND_MANAGERS,
  OrganizationPermissions.CAN_VIEW_ALL_PROJECTS,
  OrganizationPermissions.CAN_VIEW_ORGANIZATION_SETTINGS,
  OrganizationPermissions.CAN_ACCESS_BILLING_AND_TRANSACTIONS,
  OrganizationPermissions.CAN_MAKE_TOP_UPS,
  OrganizationPermissions.CAN_BUY_PLACEMENTS,
  OrganizationPermissions.CAN_CANCEL_ORDERS,
  OrganizationPermissions.CAN_DOWNGRADE_MANAGERS,
  OrganizationPermissions.CAN_CREATE_CAMPAIGNS,
  OrganizationPermissions.CAN_APPROVE_PUBLISHED_ARTICLES,
  OrganizationPermissions.CAN_EDIT_ORDERS,
  OrganizationPermissions.CAN_VIEW_UNASSIGNED_ORDERS,
  OrganizationPermissions.CAN_VIEW_CAMPAIGNS,
])

export const MANAGER_PERMISSIONS = new Set<OrganizationPermissions>([
  OrganizationPermissions.CAN_BUY_PLACEMENTS,
  OrganizationPermissions.CAN_CANCEL_ORDERS,
  OrganizationPermissions.CAN_DOWNGRADE_MANAGERS,
  OrganizationPermissions.CAN_CREATE_CAMPAIGNS,
  OrganizationPermissions.CAN_APPROVE_PUBLISHED_ARTICLES,
  OrganizationPermissions.CAN_EDIT_ORDERS,
  OrganizationPermissions.CAN_VIEW_UNASSIGNED_ORDERS,
  OrganizationPermissions.CAN_VIEW_CAMPAIGNS,
])

export const VIEWER_PERMISSIONS = new Set<OrganizationPermissions>([
  OrganizationPermissions.CAN_VIEW_UNASSIGNED_ORDERS,
  OrganizationPermissions.CAN_VIEW_CAMPAIGNS,
])
